import moment from "moment";
import {
  ADD_INSTRUCTOR,
  ADD_TAG,
  CLASES_RECIBIDAS,
  CLASS_TYPES_RECIBIDOS,
  CLEAR_SCHEDULE_UPDATE,
  CREATE_CLASE,
  HIDE_SPINNER,
  REMOVE_INSTRUCTOR,
  REMOVE_TAG,
  RESERVATIONS_RECIBIDAS,
  SEMANAS_RECIBIDAS,
  SET_CLASE,
  SET_END_DATE,
  SET_MONTH,
  SET_PROPIEDAD_CLASE,
  SET_SCHEDULE_UPDATE,
  SET_START_DATE,
  SET_VIEW,
  SET_WEEK,
  SHOW_SPINNER,
  TOTAL_RECIBIDO,
} from "../types";
import { getFriendlyLocalDateTimeString } from "../utils/dates";

const schema = {
  class_instructor_id: "nueva",
  description: "",
  location_id: "",
  class_type_id: "",
  capacity: "",
  class_date: "",
  is_virtual_class: false,
  is_special_event: false,
  tags: [],
};

const ClassInstructorReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_VIEW:
      return { ...state, view: payload };
    case SEMANAS_RECIBIDAS:
      return { ...state, days: payload };
    case RESERVATIONS_RECIBIDAS:
      return { ...state, reservations: payload };
    case SET_CLASE:
      return { ...state, clase: payload };
    case SET_PROPIEDAD_CLASE:
      const clase = { ...state.clase };
      const { key, value } = payload;
      clase[key] = value;
      return { ...state, clase };
    case CREATE_CLASE:
      return {
        ...state,
        clase: {
          ...schema,
          class_instructors: [],
          class_date: moment().format("YYYY-MM-DD HH:mm"),
        },
      };
    case CLASS_TYPES_RECIBIDOS:
      return { ...state, class_types: payload };
    case CLASES_RECIBIDAS:
      return { ...state, clases: payload };
    case TOTAL_RECIBIDO:
      return { ...state, total: payload };
    case ADD_INSTRUCTOR: {
      const { class_instructors } = state.clase;
      if (Array.isArray(class_instructors)) {
        let instructorExists = class_instructors.find(
          (current) => current.instructor_id === payload.instructor_id
        );
        if (!instructorExists) {
          class_instructors.push({
            instructor: payload,
            instructor_id: payload.instructor_id,
          });
        }
      }
      return { ...state, clase: { ...state.clase, class_instructors } };
    }
    case REMOVE_INSTRUCTOR: {
      const { class_instructors } = state.clase;
      if (Array.isArray(class_instructors)) {
        const index = class_instructors.findIndex(
          (instructor) => String(instructor.instructor_id) === String(payload)
        );
        if (index !== -1) {
          class_instructors.splice(index, 1);
        }
      }
      return { ...state, clase: { ...state.clase, class_instructors } };
    }
    case ADD_TAG: {
      const { tags } = state.clase;
      if (Array.isArray(tags)) {
        let tagExists = tags.find(
          (current) => current.tag_id === payload.tag_id
        );
        if (!tagExists) {
          tags.push({
            tag: payload,
            tag_id: payload.tag_id,
          });
        }
      }
      return { ...state, clase: { ...state.clase, tags } };
    }
    case REMOVE_TAG: {
      const { tags } = state.clase;
      if (Array.isArray(tags)) {
        const index = tags.findIndex(
          (tag) => String(tag.tag_id) === String(payload)
        );
        if (index !== -1) {
          tags.splice(index, 1);
        }
      }
      return { ...state, clase: { ...state.clase, tags } };
    }
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case SET_SCHEDULE_UPDATE:
      return { ...state, update: true };
    case CLEAR_SCHEDULE_UPDATE:
      return { ...state, update: false };
    case SET_MONTH:
      return { ...state, month: payload };
    case SET_WEEK:
      return { ...state, selectedWeek: payload };
    case SET_START_DATE:
      return { ...state, start_date: payload };
    case SET_END_DATE:
      return { ...state, end_date: payload };
    default:
      return { ...state };
  }
};

export default ClassInstructorReducer;
